import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | Finances Routes
    |--------------------------------------------------------------------------|
    */

    path: 'financials',
    name: 'financials',
    children: [
        /*
        |--------------------------------------------------------------------------
        | Expenses Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'accounting',
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/Financial/views/Accounting/ExpenseSummaryStats'),
                    name: 'expenses-summary-stats',
                    meta: {  requiresPermission: []},
                },
                {
                    path: ':company/office/:id/users/expenses/overview',
                    component: () => import('@/Modules/Financial/views/Accounting/OfficeUserExpenseOverview.vue'),
                    name: 'office-users-expenses-overview',
                    meta: {  requiresPermission: []},
                },
                {
                    path: ':company/office/:id/expenses/list',
                    component: () => import('@/Modules/Financial/views/Accounting/OfficeExpenseList.vue'),
                    name: 'office-expenses',
                    meta: {  requiresPermission: []},
                },
                {
                    path: ':company/office/:office/:id/users/expenses/list',
                    component: () => import('@/Modules/Financial/views/Accounting/OfficeUserExpenseList.vue'),
                    name: 'office-users-expenses-list',
                    meta: {  requiresPermission: []},
                },
                {
                    path: 'users/expense/:id/details',
                    component: () => import('@/Modules/Financial/views/Accounting/ExpenseDetails.vue'),
                    name: 'office-user-expense-details',
                    meta: {  requiresPermission: []},
                }
            ]
        },

        /*
        |--------------------------------------------------------------------------
        | Pipeline Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'accounts',
            component: () => import('@/Modules/Financial/views/Accounts/Index.vue'),
            name: 'finances-accounts',
            meta: { requiresPermission: [] , requiresAccess: [moduleAbilities.FUND]},
        },

        /*
        |--------------------------------------------------------------------------
        | Expenses Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'expenses',
            children: [
                {
                    path: '',
                    component:  () => import('@/Modules/Financial/views/Expense/Index.vue'),
                    name: 'expenses',
                    meta: { requiresPermission: [] , requiresAccess: [moduleAbilities.EXPENSE ] },
                },
                {
                    path: 'create/:typeId/:type',
                    component:  () => import('@/Modules/Financial/views/Expense/Detail.vue'),
                    name: 'expenses-create',
                    meta: { requiresPermission: [] , requiresAccess: [moduleAbilities.EXPENSE ] },
                },
                {
                    path: 'edit/:id',
                    component:  () => import('@/Modules/Financial/views/Expense/Detail.vue'),
                    name: 'expenses-edit',
                    meta: { requiresPermission: [] , requiresAccess: [moduleAbilities.EXPENSE ] },
                },
                {
                    path: 'view/:id',
                    component: () => import('@/Modules/Financial/views/Expense/View.vue'),
                    name: 'expenses-view',
                    meta: { requiresPermission: [] , requiresAccess: [moduleAbilities.EXPENSE ] },

                },
            ]
        },
    ]

}
