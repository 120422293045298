import * as types from './mutation-types'



export const loadAllowances = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/allowance/collection', data).then((response) => {
            commit(types.ADD_ALLOWANCES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAllowance = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/allowance', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createAllowance = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/allowance', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteAllowance = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/allowance').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

