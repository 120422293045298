import moduleAbilities from "@/composables/moduleAbilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | Chat Routes
    |--------------------------------------------------------------------------|
    */

    path: 'chats',
    children: [
        {
            path: '',
            component: () => import('@/Modules/Chat/views/Chat/Index.vue'),
            name: 'chats',
            meta: {  requiresPermission: []},
        },

        {
            path: ':id/messages',
            component: () => import('@/Modules/Chat/views/Chat/Index.vue'),
            name: 'chats-conversation-messages',
            meta: {  requiresPermission: []},
        },
    ]

}
