import {computed} from "vue";
import i18n from '@/plugins/i18n';
import router from '../router/index'
import { format } from 'date-fns'
import {isTypedArray} from "core-js/internals/array-buffer-view-core";
import { parse } from 'date-fns';

const locale = computed(() => i18n.global.locale)
export const appVersion = computed(() => process.env.VUE_APP_VERSION);
export const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_KEY);
export const secretKey = computed(() => process.env.VUE_APP_RECAPTCHA_PRIVATE_KEY);

export function formattedLocalAmount(amount) {
  const local = locale.value;
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };

  const parsedAmount = parseFloat(amount);

  if (!isNaN(parsedAmount) && Number.isFinite(parsedAmount)) {
    if (Number.isInteger(parsedAmount)) {
      return parsedAmount.toLocaleString(local, options);
    } else {
      if (local === 'fr') {
        return parsedAmount.toLocaleString('fr-FR', options);
      } else {
        return parsedAmount.toLocaleString('en-US', options);
      }
    }
  }

  // Retourner la valeur d'origine si la conversion a échoué
  return amount;
}

export function $formatBirthdayDate(date){
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString('fr-FR', { month: 'long', day: 'numeric' });
}

export function $formatDate(d) {
  var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month,year].join('/');
}

export function $dateFormatter(date, locale, long = false, includeTime = false, customOptions = null){
    const defaultOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    const longOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
    };

    let options = long ? longOptions : defaultOptions;

    if (includeTime) {
        options = { ...options, ...timeOptions };
    }

    return new Date(date).toLocaleDateString(locale, customOptions || options);
}

export function removeProtocol(url) {
    if (!url) {
      return ''
    }

    return url.replace(/(^\w+:|^)\/\//, '')
}

export function getUrlProtocol(url) {
    if (!url) {
      return ''
    }

    let arrayUrl = url.split('//')

    return arrayUrl[0]
}

export function $formatLocalAmount(amount, locale= 'fr') {
  // const locale = this.i18n.global.locale
  const options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
  };

  const parsedAmount = parseFloat(amount);

  if (!isNaN(parsedAmount) && Number.isFinite(parsedAmount)) {
      if (Number.isInteger(parsedAmount)) {
          return parsedAmount.toLocaleString(locale, options);
      } else {
          if (locale === 'fr') {
              return parsedAmount.toLocaleString('fr-FR', options);
          } else {
              return parsedAmount.toLocaleString('en-US', options);
          }
      }
  }

  return amount;
}

export function $goBack() {
  router.go(-1)
}

export function formatDate(date) {
    if(!date){
        return ''
    }

    const d = new Date(date)
    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [day, month,year].join('-');

}

export function applyDiscount (discountType, discountVal, amount) {
    if (discountType !== 'amount') {
        return (parseFloat(amount) * parseFloat(discountVal) / 100)
    }
    return discountVal
}


export function getTenantId(){
  let host = window.location.host;
  let parts = host.split('.');
  let subDomainLength = 3; // without www
  let wwwSubDomainLength = 4; // with www

  if(window.location.hostname === 'localhost'){
    return 'app.'
  }

  if(parts.length === subDomainLength && parts[0] !== 'www'){
    return parts[0] + "."
  }else if(parts.length === wwwSubDomainLength && parts[0] === 'www'){
    return parts[1] + "."
  }

  if(parts[0] && parts[0] !== "www"){
    return parts[0] + "."
  }

  return window.location.hostname === 'localhost' ? 'app.' : ""
}

export function isSubDomain(){
    let host = window.location.host;
    host = host.replace('http://', '')
    host = host.replace('https://', '')
    host = host.replace('www.', '')
    host = host.replace(':'+process.env.VUE_APP_PORT, '')
    host = host.replace(process.env.VUE_APP_HOST, '')
    return !!host
}

export function formatRemainingTime(dateStr) {
  const now = new Date();
  const past = new Date(dateStr);

  // Calculer la différence en millisecondes
  const diffMs = now - past;

  // Conversion en différentes unités de temps
  const diffSeconds = diffMs / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;
  const diffWeeks = diffDays / 7;
  const diffMonths = diffDays / 30.44; // Approximation moyenne des jours par mois

  // Décider le format d'affichage
  if (diffMonths >= 1) {
      return `${Math.floor(diffMonths)} ${i18n.global.t("months")}`;
  } else if (diffWeeks >= 1) {
      return `${Math.floor(diffWeeks)}  ${i18n.global.t("weeks")}`;
  } else if (diffDays >= 1) {
      return `${Math.floor(diffDays)}  ${i18n.global.t("days")}`;
  } else if (diffHours >= 1) {
      return `${Math.floor(diffHours)}  ${i18n.global.t("hours")}`;
  } else {
      return `${Math.floor(diffMinutes)}  ${i18n.global.t("minutes")}`;
  }
}

export function convertSeconds(seconds) {
    const currentTime = new Date();
    const pastTime = new Date(currentTime.getTime() - seconds * 1000);

    const diff = currentTime - pastTime;

    if (diff < 60000) {
        return `${i18n.global.t("justNow")}`;
    } else if (diff < 3600000) {
        const minutes = Math.floor(diff / 60000);
        return `${i18n.global.t("minutesAgo", {field: minutes})}`;
    } else if (diff < 86400000) {
        const hours = Math.floor(diff / 3600000);
        return `${i18n.global.t("hoursAgo", {field: hours})}`;
    } else if (diff < 604800000) {
        const days = Math.floor(diff / 86400000);
        return `${i18n.global.t("daysAgo",  {field: days})}`;
    } else if (diff < 2592000000) {
        const weeks = Math.floor(diff / 604800000);
        return `${i18n.global.t("weeksAgo", {field: weeks})}`;
    } else if (diff < 15552000000) {
        const months = Math.floor(diff / 2592000000);
        return `${i18n.global.t("monthsAgo", {field: months})}`;
    } else {
        const day = String(pastTime.getDate()).padStart(2, '0');
        const month = String(pastTime.getMonth() + 1).padStart(2, '0');
        const year = pastTime.getFullYear();
        return `${day}/${month}/${year}`;
    }
}

export function createFormData(data, formData = new FormData(), parentKey = '') {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;
            if (data[key] instanceof Object && !(data[key] instanceof File) ) {
                createFormData(data[key], formData, fullKey);
            } else {
                formData.append(fullKey, data[key]);
            }
        }
    }
    return formData;
}


export function addDay(dateString, day) {
  const [jour, mois, annee] = dateString.split('-').map(Number);
  const date = new Date(annee, mois - 1, jour);

  const dateFin = new Date(date);
  dateFin.setDate(dateFin.getDate() + day);

  const resultDay = dateFin.getDate().toString().padStart(2, '0');
  const resultMonth = (dateFin.getMonth() + 1).toString().padStart(2, '0');
  const resultYear = dateFin.getFullYear();

  return `${resultDay}-${resultMonth}-${resultYear}`;
}

export function formatTodayDate(){
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  const formattedStartDate = `${day}-${month}-${year}`;
  return formattedStartDate
}

export function compareDateToToday(start){
  if(start){
    const today = new Date();
    const startDate = parse(start, 'dd-MM-yyyy HH:mm', new Date());
    return startDate > today
  }
  return false
}

export function getClientTimezone(){
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function calculateByType (val, type, amount) {
    if (type !== 'amount') {
        return (parseFloat(amount) * parseFloat(val) / 100) ?? 0
    }
    return amount ?? 0
}



export const calculateEachItemValue = (baseVal = 0.0, content = {}) => {
    const isWithoutBracket = !content?.range_min && !content?.range_max
    if(isWithoutBracket){
        console.log("isWithoutBracket", content)
        return calculateByType(baseVal, content.type, content.value);
    } else {
        console.log("isWithBracket", content)
        let value = 0

        if (content?.range_min && !content?.range_max) {
            if(baseVal > content?.range_min) {
                value = calculateByType(
                    baseVal - content?.range_min,
                    content?.type,
                    content?.value
                );
            }
        } else if (content?.range_min && content?.range_max) {
            console.log("ssdsd content?.range_min && content?.range_max:", content?.range_min && content?.range_max)
            if(baseVal <= content?.range_max) {
                value = calculateByType(
                    baseVal - content?.range_min,
                    content?.type,
                    content?.value
                );
            }else {
                value = calculateByType(
                    content?.range_max - content?.range_min,
                    content?.type,
                    content?.value
                );
            }
        } else if(!content?.range_min && content?.range_max) {
            if(baseVal > content?.range_max) {
                value = calculateByType(
                    content?.range_max,
                    content?.type,
                    content?.value
                );
            } else {
                value = calculateByType(
                    baseVal,
                    content?.type,
                    content?.value
                );
            }
        }

        console.log("after calc", value)
        return value
    }
}

export const calculatePayslipItemValue = (baseVal = 0.0, contents = []) => {
    let value = 0

    contents.forEach(item => {
        value += calculateEachItemValue(baseVal, item)
    })

    return value;
}

export function getMonthLocalName(month, year, locale) {
    const date = new Date(year+"-"+month+"-"+"01")
    const options = { month: "long" };
    return new Intl.DateTimeFormat(locale, options).format(date)
}