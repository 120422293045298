<template>
  <Card container-class="p-0 card shadow-sm">
    <template #cardHeader>
      <div class="card-header">
        <h2 class="card-title fw-bolder">{{title}}</h2>
        <div class="card-toolbar">
          <button v-if="show" @click="sendInvitation" class="btn btn-flex btn-primary me-2">
            <i class="bi bi-send"></i>
            {{$t('nps.send_invitation')}}
          </button>
          <button v-if="show" @click="goToPreview" class="btn btn-flex btn-primary me-2">
            <i class="bi bi-eye"></i>
            {{$t('nps.form.preview')}}
          </button>
          
        </div>
      </div>
    </template>
    <template #cardBody>
      <Card class="card p-5">
        <div class="mb-5">
          <Input
              :name="`${id}-title`"
              type="text"
              :label="$t('nps.title')"
              :isRequired="true" size="lg"
              v-model="formData.title"
          />
         <div class="row">
          <div class="col-9">
            <Input
              :name="`${id}-desc`"
              type="text"
              :label="$t('nps.question')"
              :isRequired="true" size="lg"
              v-model="formData.nps_description"
            />
          </div>
          <div class="col-3">
            <Select2
                :name="'icon'"
                :multiple="false"
                :label="$t('nps.form.icons')"
                :isRequired="true"
                v-model="formData.icon"
                >
              <option v-for="icon in icons" :key="icon.value" :value="icon.value">
                {{ icon.name }}
              </option>
            </Select2>
          </div>
         </div>
        </div>
      </Card>
      <br>
      <div class="d-flex flex-row-reverse">
        <button @click="addField" class="btn btn-flex btn-primary" >
            <span class="svg-icon svg-icon-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opaicon="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
              </svg>
            </span>
            {{$t('nps.form.add_field')}}
        </button>
      </div>
      <br>
      <Card class="p-0 card shadow-sm mb-5" v-for="(field, index) in formData.fields" :key="index">
        <div class="table-responsive mx-4">
          <table class="table table-row-gray-200 align-middle mb-0 gs-0">
            <thead>
            <tr class="border-0">
              <th class="p-0 w-200px"></th>
              <th class="p-0 w-250px"></th>
              <th class="p-0 w-80px"></th>
              <th class="p-0 w-80px"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <a href="#" class="text-primary mb-1 fs-6">{{$t('nps.form.type')}}</a>
                <span class="text-muted d-block">{{ getTypeLocalName(field.type) }}</span>
              </td>
              <td>
                <a href="#" class="text-primary mb-1 fs-6">{{$t('nps.form.name')}}</a>
                <span class="text-muted d-block">{{ field.label }}</span>
              </td>
              <td class="text-end">
                <span v-if="field.required" class="badge badge-success">{{$t('nps.form.required')}}</span>
                <span v-else class="badge badge-danger">{{$t('nps.form.not_required')}}</span>
              </td>
              <td class="text-end">
                <button @click="editField(index)" class="btn btn-sm btn-icon btn-light-success me-3">
                  <i class="bi bi-pencil-square"></i>
                </button>
                <button v-if="field.can_delete" @click="removeField(index)" class="btn btn-sm btn-icon btn-light-danger">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </template>
    <template #cardFooter> 
      <div class="d-flex justify-content-end">
        <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.reset" @click="resetForm"></Button>
        <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :loading="loading" @click="handleFormSubmit"></Button>
      </div>
    </template>
  </Card>
  <FieldFormModal id="new_field" :form="fieldForm" @save="handleFieldFormChange"/>
</template>
<script setup>

import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import FieldFormModal from "./FieldFormModal.vue";
import { useLocales } from "@/composables/locales";
import { useSwal } from "@/composables/useSwal";
import Button from "@/components/Button.vue";
import Card from "@/components/Card.vue";
import Input from "@/components/Input.vue";
import { useNpsIcon } from "../composable/useNpsIcon";
import Select2 from "@/components/Select2.vue";

const props = defineProps({
  submitForm: {type: Function, required: true},
  defaultForm: {type: Array, default: []},
  title: {type: String, default: ""},
  loading: {type: Boolean, default: false},
  getting: {type: Boolean, default: false}, 
  show: {type: Boolean, default: false}, 
})

const store = useStore()
const router = useRouter()
const route = useRoute()
const {showSwal} = useSwal()
const {locale} = useLocales()
const { icons } = useNpsIcon()

const fieldForm = ref({
  type: "",
  label: "",
  name: "",
  placeholder: "",
  required: true,
  options: [],
  extensions: [],
  repeatable: false,
 // can_delete: true,
})

const formData = ref(props.defaultForm)

const activeField = ref(0)

const types = computed(() => store.getters['nps/types']) 
const company = computed(() => store.getters['offices/getSelectedCompany'])

watch(() => company.value, (value) => {
  if(value) {
   formData.value.society_id = company.value.id 
  }
}, {deep: true})

watch(() => props.defaultForm, (value) => {
  if(value) {
    formData.value = props.defaultForm
  }
}, {deep: true})

function getTypeLocalName(value){
  let name = value
  let type = types.value.filter(el => el.value == value)

  if(type?.length>0){
    name = type[0][locale.value]
  }
  return name
}

function addField(){
  activeField.value = formData.value.fields?.length

  fieldForm.value = {
    type: "",
    label: "",
    name: "",
    placeholder: "",
    required: true,
    options: [],
    extensions: [],
    repeatable: false,
    //can_delete: true,
  }

  nextTick(() => {
    $('#new_field').modal('toggle')
  });
}

function editField(fieldIndex){
  activeField.value = fieldIndex
  fieldForm.value = JSON.parse(JSON.stringify(formData.value.fields[fieldIndex]))
  console.log(fieldForm.value)

  nextTick(() => {
    $('#new_field').modal('toggle')
  });
}

function removeField(fieldIndex){
  showSwal(
      i18n.global.t('alert.remove_field') ,
      i18n.global.t('alert.remove_field_text'),
      'error'
  ).then( async (result) => {
    if (result.value) {
        if (fieldIndex <= formData.value.fields?.length) {
          let data = formData.value.fields
          data.splice(fieldIndex, 1)
          activeField.value = 0
          formData.value.fields = data

          await nextTick(() => {
            handleFormSubmitAfterFieldChange()
          })
        }

    }
  })
}

function handleFormSubmitAfterFieldChange(){
  props.submitForm({data: formData.value, submit: false})
}

function handleFormSubmit(){
  props.submitForm({data: formData.value, submit: true})
}

function resetForm(){
 formData.value = props.defaultForm
}

function handleFieldFormChange(data){
  let fields = formData.value.fields
  fields.splice(activeField.value, 1, data)
  formData.value.fields = fields
  nextTick(() => {
    handleFormSubmitAfterFieldChange()
  })
}

onMounted(() => {
  store.dispatch("contacts/loadContacts")
})

function goToPreview() {
  router.push({name:"nps-preview", params: {id: route.params.id}})
}

function sendInvitation(){
  $('#send_nps_invitation').modal('toggle')

}

</script>
<style scoped>
.card .card-body{
  padding: 0 !important;
}

.accordion-item{
  border: 0 !important;
}
.accordion-header{
  border-radius: .625rem !important;
}

.accordion-button{
  border-radius: .625rem !important;
}
</style>