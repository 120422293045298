import * as types from './mutation-types'

export const loadOfficesBySocieties = ({ commit }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/societies').then((response) => {
            commit(types.LOAD_OFFICES, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadSocietyOffices = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/society/'+ id +'/offices').then((response) => {
            commit(types.LOAD_SOCIETY_OFFICES, response.data.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadSocietyEmployee = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/'+ id +'/society/employee').then((response) => {
            console.log( response.data.data)
            commit(types.LOAD_SOCIETY_EMPLOYEE, response.data.users)
        }).catch((err) => {
            reject(err)
        })
    })
}
