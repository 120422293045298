<template>
  <BaseGlobalLoader v-if="isLoaded"/>
  <router-view :key="currentCompanyId" v-else />
</template>


<script setup>
import 'nprogress/nprogress.css'
import '../public/assets/css/nprogress.custom.css'
import BaseGlobalLoader from '@/components/BaseGlobalLoader'
import {computed, nextTick, watch} from 'vue';
import {useStore} from "vuex";
const store = useStore()
const isLoaded = computed(()=> store.getters['isAppLoaded'])
const currentCompanyId = computed(() => store.getters['offices/getSelectCompanyId'])

</script>
<style scoped>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


</style>
