import * as types from './mutation-types'
import {createFormData} from "@/mixins/Helpers";

export const loadProjects = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/card/list?office_id='+data.office+'&startData='+data.start_date+'&endDate='+data.end_date).then((response) => {
            commit(types.LOAD_PROJECTS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createComment = ({commit}, {comment, id}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`store/project/${id}/comment`, {
            comment: comment
        }).then((response) => {
            commit(types.ADD_PROJECT_COMMENT, {
                comment: response.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getProjectStatusStat = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/status/Stats?startDate='+data.start_date+'&endDate='+data.end_date+'&office_id='+data.office).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectComments = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/project/${id}/comments`).then((response) => {
            commit(types.ADD_PROJECT_COMMENTS, {
                comments: response.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeProject = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/project', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProjectComment = ({commit}, {id, project}) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`delete/comment/${id}`).then((response) => {
            commit(types.REMOVE_PROJECT_COMMENT, {id, project})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const showProject = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get('show/' + data.project_id + '/project', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectDirectories = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`get/project/${id}/folder/collection`).then((response) => {
            commit(types.ADD_PROJECT_DIRECTORIES, {
                directories: response.data.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addUsersToProject = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('add/project/'+ data.project_id +'/users', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createDirectory = ({commit}, {name, id}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`store/project/${id}/folder`, {
            name: name
        }).then((response) => {
            commit(types.ADD_PROJECT_DIRECTORY, {
                directory: response.data.data,
                id: id
            })
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteDirectory = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+data.projectFolder+'/project/folder').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createDirectoryFile = ({commit}, data) => {
    let formSubmit = createFormData(data)
    return new Promise((resolve, reject) => {
        window.axios.post(`upload/projectFolder/${data.project_folder_id}/files`, formSubmit).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteDirectoryFile = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`delete/projectFolder/${data.project_folder_file_id}/file`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const downloadProjectFile = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.get('download/file/'+data.id, { responseType: 'blob' }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.name);
            document.body.appendChild(link);
            link.click();
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteAllDirectoryFiles = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`delete/projectFolder/${data.project_folder_file_id}/multi/files`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const updateProject = ({commit}, data) => {
    console.log("ddd", data)

    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/project', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProject = ({commit}, data) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+data.project+'/project').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadProjectCategories = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/projects/categories/collection').then((response) => {
            commit(types.LOAD_PROJECT_CATEGORIES, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const storeProjectCategory = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('store/project/category', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateProjectCategory = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.put('update/' + data.id + '/project/category', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteProjectCategory = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+'/project/category').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
