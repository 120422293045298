<template>
    <div class="background-image">
      <div class="container">
        <div class="content">
            <div class="row m-10">
                <form v-if="validity && !getting" class="form w-100" id="kt_kyc_form" @submit.prevent="submitResponse">
                    <Card class="card p-5" :title="nps?.title">
                        <div class="modal-body">
                            <Card class="card p-5 justify-ceontent-center align-items-center">
                                <Label labelClass="form-label fs-3 fw-bolder" :label="nps?.nps_description" :required="true"/>
                                <NpsInput v-model="formData.note" v-if="nps.icon == ICONS.EMOJI" :type="ICONS.EMOJI"/>
                                <NpsInput  v-model="formData.note" v-if="nps.icon == ICONS.STAR" :type="ICONS.STAR"/>
                            </Card>
                            <br>
                            <FormField
                                v-for="field in nps?.form?.fields"
                                :key="field.id"
                                :form-data="field"
                                :form-errors="formErrors"
                                :withId="false"
                                v-model="formData" /> 
                        </div>
                        <template #cardFooter> 
                           <div class="d-flex justify-content-end">
                                <Button type="cancel" id="cancel_btn" btn-class="btn-sm me-3" size="7px 30px" btn-text="button.reset" @click="resetForm"></Button>
                                <Button btn-class="btn-sm me-3" type="submit" id="submit_btn" btn-text="button.save" size="7px 30px" :loading="loading" @click="handleFormSubmit"></Button>
                            </div> 
                        </template>
                    </Card>
                </form>
                <Card v-if="!validity && !getting" class="card p-5 h-full">
                  <div class=" text-center ">
                    <div class="pt-8 ">
                      <img src="/assets/media/empty.png" alt=""
                          class="mw-100 h-125px h-sm-175px" />
                    </div>
                    <h2 class="fs-2x fw-bolder mb-0">{{ $t('nps.alwredyAnswered') }}</h2>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>

                  </div>
                </Card>
            </div>
        </div>
      </div>
    </div>
  </template>
  
<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import SubLayout from "@/views/Layouts/partials/SubLayout.vue";
import {useStore} from "vuex";
import {useRouter, useRoute} from "vue-router";
import {useLocales} from "@/composables/locales";
import {useHasAbility} from "@/composables/hasAbility";
import {Theme} from "@/composables/theming";
import {useModelViewType} from "@/composables/modelViewType";
import NpsCard from "../../components/NpsCard.vue";
import NpsForm from "../../components/NpsForm.vue";
import Card from "@/components/Card.vue";
import FormField from "../../components/FormField.vue";
import { useNpsIcon } from "../../composable/useNpsIcon";
import { reactive } from "vue";
import Button from "@/components/Button.vue";
import Label from "../../components/Label.vue";
import NpsInput from "../../components/Inputs/NpsInput.vue";
  
  const route = useRoute()
  const router = useRouter()
  
  const store = useStore()
  const { ICONS } = useNpsIcon()
  const { locale } = useLocales()
  
  const title = ref("breadcrumbs.nps")
  const breadcrumbs = ref([
  {
    name: 'breadcrumbs.preview',
    route: 'nps'
  }
  ])
  
  const getting = ref(false)
  const loading = ref(false)

  const nps = ref({})

  const validity = ref()
  const formData = reactive({
    note: "",
    nps_id: route.params.id,
    invitation_id: route.params.invitation
  })
  const formErrors = ref({})

  const form = ref({
    checkValidity: true,
    id: route.params.id,
    invitation: route.params.invitation
  })
  
  const company = computed(() => store.getters['offices/getSelectedCompany'])
  
  watch(() => [route.params.id,route.params.invitation], () => {
    if(route.params.id){
      loadNps()
    }
  })
  
  const loadNps= () => {
    getting.value = true
    store.dispatch('nps/getClienNps', form.value).then((resp) => {
      nps.value = resp.data.data
      validity.value = resp.data.formIsValid
      initForm(resp.data.data)
    })
    .finally(() => getting.value = false)
  }
  
  
  function submitResponse(){
    loading.value = true
    store.dispatch('nps/saveNpsResponse', formData)
        .then(res => {
          window.toastr['success'](i18n.global.t("notification.nps_response_submited"))
          validity.value = false
        })
        .catch((err) => {
        })
        .finally(() => loading.value = false)
  }

  
  function initForm(nps){
    
  }
  
  onMounted(() => {
    if(route.params.id){
      loadNps()
    }
  })
  
  </script>
  <style scoped>
  .container {
    
  }
  
  .content {
  }
  
  .icon {
    font-size: 50px;
    margin-bottom: 20px;
  }
  
  .background-image {
    background: 
        linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1)),
        url('../../../../../public/assets/media/empty_bg.png') no-repeat center center,
        url('../../../../../public/assets/media/empty_bg.png') no-repeat center center;
    background-size: cover, cover, cover;
    height: 100vh;
    width: 100%; 
  }
  </style>