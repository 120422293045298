import * as types from './mutation-types'

export default {
  [types.LOAD_PROJECT_CATEGORIES](state, data) {
    state.project_categories = data.data
  },
  [types.LOAD_PROJECTS](state, data) {
    state.projects = data.data
  },
  [types.ADD_PROJECT_COMMENTS](state, {comments, id}) {
    state.projectComments = {...state.projectComments, [id]: comments}
  },

  [types.ADD_PROJECT_COMMENT](state, {comment, id}) {
    let c = state.projectComments[id] || []
    c.unshift(comment)
    state.projectComments = {...state.projectComments, [id]: c}
  },

  [types.REMOVE_PROJECT_COMMENT](state, {id, project}) {
    let c = state.projectComments[project] || []
    c = c.filter(cc => cc.id !== id)
    state.projectComments = {...state.projectComments, [project]: c}
  },

  [types.ADD_PROJECT_DIRECTORIES](state, {directories, id}) {
    state.projectDirectories = {...state.projectDirectories, [id]: directories}
  },

  [types.ADD_PROJECT_DIRECTORY](state, {directory, id}) {
    let dir = state.projectDirectories[id] || []
    dir.unshift(directory)
    state.projectDirectories = {...state.projectDirectories, [id]: dir}
  },
}
