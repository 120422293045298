import * as types from './mutation-types'

export default {
  [types.LOAD_OFFICES](state, data) {
    state.offices = data
  },
  [types.LOAD_SOCIETY_OFFICES](state, data) {
    state.society_offices = data
  },
  [types.LOAD_SOCIETY_EMPLOYEE](state, data) {
    state.employees = data

  },
  [types.SET_SELECTED_COMPANY](state, data) {
    state.selectedCompany = data
  },
}
