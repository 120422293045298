import Ls from '../../../services/ls'
import * as types from './mutation-types'
import * as userTypes from '../user/mutation-types'
import * as rootTypes from '../../mutation-types'
import router from '../../../router/index'

export const login = ({ commit, dispatch, state }, data) => {
    const loginData = {
        email: data.email,
        password: data.password
    }
    return new Promise((resolve, reject) => {
        window.axios.post('login', loginData).then((response) => {
            const token = response.data.token
            Ls.set('auth.token', token)

            commit('user/' + userTypes.BOOTSTRAP_CURRENT_USER,  response.data.user, { root: true })
            commit(rootTypes.UPDATE_APP_LOADING_STATUS, false, { root: true })

            commit(types.AUTH_SUCCESS, token)
            resolve(response)
        }).catch(err => {
            commit(types.AUTH_ERROR, err.response)
            Ls.remove('auth.token')
            reject(err)
        })
    })
}


export const createPassword = ({ commit, dispatch, state }, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('create/password',data).then((response) => {
            //const token = response.data.token
            //Ls.set('auth.token', token)

            //commit('user/' + userTypes.BOOTSTRAP_CURRENT_USER,  response.data.user, { root: true })
            //commit(rootTypes.UPDATE_APP_LOADING_STATUS, false, { root: true })

            //commit(types.AUTH_SUCCESS, token)
            resolve(response)
        }).catch(err => {
            commit(types.AUTH_ERROR, err.response)
            Ls.remove('auth.token')
            reject(err)
        })
    })
}



export const createAccount = ({ commit, dispatch, state }, data) => {
    let form = new FormData
    form.append("society", data.society_name)
    form.append("ifu_number", data.matricule_number)
    form.append("society_email", data.society_email)
    form.append("society_phone", data.society_phone)
    form.append("society_phone_code", data.society_phone_code)
    form.append("responsable_gender", data.responsable_gender)
    form.append("responsable_lastname", data.responsable_lastname)
    form.append("responsable_firstname", data.responsable_firstname)
    form.append("responsable_email", data.responsable_email)
    form.append("responsable_phone", data.responsable_phone)
    form.append("responsable_phone_code", data.responsable_phone_code)
    form.append("address", data.address)
    form.append("city", data.city)
    form.append("zipcode", data.zipcode)
    form.append("tva_number", data.tva_number)
    form.append("rccm_number", data.rccm_number)
    form.append("rccm_file", data.rccm_file[0])
    form.append("address_file", data.address_file[0])
    form.append("ifu_file", data.ifu_file[0])
    form.append("gender", data.gender)
    form.append("lastname", data.lastname)
    form.append("firstname", data.firstname)
    form.append("email", data.email)
    form.append("website", data.website)
    form.append("country_id", data.country)
    form.append("currency_id", data.currency_id)
    form.append("domain", data.domain)


    form.append('_method', 'POST');

    return new Promise((resolve, reject) => {
        window.axiosDefault.post('inscription', form , {headers: {'Content-type': 'multipart/form-data'}}).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}




export const updateAccount = ({ commit, dispatch, state }, data) => {
    let form = new FormData
    form.append("society", data.society);
    form.append("society_email", data.society_email);
    form.append("society_phone", data.society_phone);
    form.append("society_phone_code", data.society_phone_code);
    form.append("address", data.address);
    form.append("city", data.city);
    form.append("zipcode", data.zipcode);
    form.append("country_id", data.country_id);
    form.append("currency_id", data.currency_id);
    form.append("headquarters_address", data.headquarters_address);
    form.append("legal_form", data.legal_form);
    form.append("logo", data.logo_url);

    form.append('_method', 'PATCH'); 

    
    return new Promise((resolve, reject) => {
        window.axios.post(`update/company`, form, { headers: { 'Content-type': 'multipart/form-data' } })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const forgetPassword= ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('forget/password', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const resetPassword= ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('reset/password', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const refreshToken = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        let data = {
            token: Ls.get('auth.token')
        }

        window.axios.post('/api/auth/refresh_token', data).then((response) => {
            let token = response.data.data.token
            Ls.set('auth.token', token)
            commit(types.REFRESH_SUCCESS, token)
            resolve(response)
        }).catch(err => {
            reject(err)
        })
    })
}

export const logout = ({ commit, dispatch, state }, noRequest = false) => {
    if (noRequest) {
        commit(types.AUTH_LOGOUT)
        Ls.remove('auth.token')
        Ls.remove('current.company')
        router.push('/login')

        return true
    }

    return new Promise((resolve, reject) => {
        axios.post('logout').then((response) => {
            commit(types.AUTH_LOGOUT)
            Ls.remove('auth.token')
            Ls.remove('current.company')
            router.push('/login')
        }).catch(err => {
            reject(err)
            commit(types.AUTH_LOGOUT)
            Ls.remove('auth.token')
            Ls.remove('current.company')
            router.push('/login')
        })
    })
}

export const getUserByToken = ({ commit, dispatch, state },data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/token/' + data + '/user').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



