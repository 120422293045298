import * as types from './mutation-types'
import {getConversation} from "@/Modules/Chat/store/modules/chat/getters";

export const loadConversations = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('/chat/conversations').then((response) => {
            commit(types.ADD_CONVERSATIONS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadMessages = ({commit, getters}, conversationId) => {
    if(!getters.getConversation(conversationId).loaded) {
        return new Promise((resolve, reject) => {
            window.axios.get(`chat/conversations/${conversationId}/messages`).then((response) => {
                commit(types.ADD_MESSAGES, {
                    messages: response.data.data,
                    id: conversationId,
                    count: response.data.data.length,
                })
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export const sendMessage = ({commit}, {content, conversationId}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/conversations/${conversationId}/messages`, {
            message: content,
        }).then((response) => {
            commit(types.ADD_MESSAGE, { message: response.data.data, id: conversationId})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createPersonalDiscussion = ({commit}, formData) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/conversations`, formData).then((response) => {
            commit(types.ADD_CONVERSATION, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createGroupDiscussion = ({commit}, formData) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/group/conversations`, formData).then((response) => {
            commit(types.ADD_CONVERSATION, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}



export const sendAudio = ({commit}, {audio, conversationId}) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`chat/conversations/${conversationId}/messages/audio`, audio).then((response) => {
            commit(types.ADD_MESSAGE, { message: response.data.data, id: conversationId})
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const loadAllUsers = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`chat/all/users`).then((response) => {
            commit(types.ADD_USERS, response.data.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

