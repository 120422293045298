import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";
import { name } from "vue-number-format";

export default {
    /*
    |--------------------------------------------------------------------------
    | RH Routes
    |--------------------------------------------------------------------------|
    */

    path: 'nps',
    name: 'nps',
    children: [
        {
            path: '',
            component: () => import('@/Modules/Nps/views/Nps/Index.vue'),
            name: 'nps-list',
            meta: {},
        },
        {
            path: 'create',
            component: () => import('@/Modules/Nps/views/Nps/Create.vue'),
            name: 'nps-create',
            meta: {},
        },
       {
        path: "views",
        name: "nps-views",
        component: () => import('@/Modules/Nps/views/Nps/Layouts/NpsViewLayout.vue'),
        children:[
            {
                path: ':id/details',
                component: () => import('@/Modules/Nps/views/Nps/View.vue'),
                name: 'nps-details',
                meta: {},
            },
            {
                path: ':id/preview',
                component: () => import('@/Modules/Nps/views/Nps/Preview.vue'),
                name: 'nps-preview',
                meta: {},
            },
            {
                path: ':id/responses',
                component: () => import('@/Modules/Nps/views/Nps/Responses.vue'),
                name: 'nps-responses',
                meta: {},
            },
            {
                path: ':id/responses/:response/details',
                component: () => import('@/Modules/Nps/views/Nps/ResponseOverview.vue'),
                name: 'nps-responses-details',
                meta: {},
            },
            {
                path: ':id/statistics',
                component: () => import('@/Modules/Nps/views/Nps/Statistics.vue'),
                name: 'nps-statistics',
                meta: {},
            },
        ]

       }
    ]

}
