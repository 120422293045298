export default {

    PIPELINE : 'PIPELINE',
    ESTIMATE : 'ESTIMATE',
    INVOICE : 'INVOICE',
    APPOINTMENT : 'APPOINTMENT',
    EXPENSE : 'EXPENSE',
    FUND : 'FUND',

}
