import { createI18n } from 'vue-i18n';
import merge from 'lodash.merge'
import en from './en.json';
import fr from './fr.json';

import crmFr from '@/Modules/CRM/lang/fr.json';
import crmEn from '@/Modules/CRM/lang/en.json';

import financeFr from '@/Modules/Financial/lang/fr.json';
import financeEn from '@/Modules/Financial/lang/en.json';

import rhFr from '@/Modules/RH/lang/fr.json';
import rhEn from '@/Modules/RH/lang/en.json';

import fmFr from '@/Modules/FileManager/lang-module/fr.json';
import fmEn from '@/Modules/FileManager/lang-module/en.json';

import npsFr from '@/Modules/Nps/lang/fr.json';
import npsEn from '@/Modules/Nps/lang/en.json';

import projectsFr from '@/Modules/Projects/lang/fr.json';
import projectsEn from '@/Modules/Projects/lang/en.json';

import chatFr from '@/Modules/Chat/lang/fr.json';
import chatEn from '@/Modules/Chat/lang/en.json';

const french = merge({}, fr, crmFr,financeFr, rhFr , fmFr ,npsFr, projectsFr, chatFr);
const english = merge({}, en, crmEn, financeEn, rhEn,  fmEn , npsEn,projectsEn, chatEn);


const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || 'fr',
  messages: {
    en: english,
    fr: french,
  },
});

export default i18n;
