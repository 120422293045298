import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";

const projectGroup = ['projects-index', 'projects-view', 'tasks-view']

export default {
    menus:  {
        sectionTitle: i18n.global.t('menu.projects.title'),
        module : [],
        permission: [],
        children: [
            {
                title: i18n.global.t('menu.projects.title'),
                icon: 'fas fa-folder icon-right2',
                route: 'projects-index',
                group: projectGroup,
                id: 'step-projects',
                permission: [],
                children: [],
                module: [],
            },
        ]
    }

}
