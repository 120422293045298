<template>
  <div class="h-100">
    <div class="d-flex flex-column flex-root align-items-stretch h-100">
      <div class="page d-flex flex-row align-items-stretch flex-column-fluid">
        <div id="kt_aside"
             class="aside aside-dark aside-hoverable"
             data-kt-drawer="true"
             data-kt-drawer-name="aside"
             data-kt-drawer-activate="{default: true, lg: false}"
             data-kt-drawer-overlay="true"
             data-kt-drawer-width="{default:'200px', '300px': '250px'}"
             data-kt-drawer-direction="start"
             data-kt-drawer-toggle="#kt_aside_mobile_toggle">

          <div class="aside-logo flex-column-auto" id="kt_aside_logo">
            <router-link :to="'/admin/dashboard'">
              <a href="#" >
                <img alt="Logo" src="/assets/media/logo.png" class="h-25px logo" />
              </a>
            </router-link>

            <div @click.prevent="toggleAside" id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
                 data-kt-toggle="true"
                 data-kt-toggle-state="active"
                 data-kt-toggle-target="body"
                 data-kt-toggle-name="aside-minimize">
              <span class="svg-icon svg-icon-1 rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.5"
                        d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                        fill="currentColor" />
                  <path
                      d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                      fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>

          <app-menu></app-menu>
        </div>

        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <app-header :numberNotification=" 0"></app-header>
          <router-view></router-view>
          <app-footer></app-footer>
        </div>

      </div>
    </div>
    <div id="kt_drawer_chat" class="bg-body" data-kt-drawer="true" data-kt-drawer-name="chat"
         data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}"
         data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle"
         data-kt-drawer-close="#kt_drawer_chat_close">
      <div class="card w-100 rounded-0 border-0" id="kt_drawer_chat_messenger">
        <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
          <div class="card-title">
            <div class="d-flex justify-content-center flex-column me-3">
              <a class="nav-link active">Notifications ({{ notifications ? notifications.length : 0 }})</a>
            </div>
          </div>
          <div class="card-toolbar">
            <div class="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_chat_close">
              <span class="svg-icon svg-icon-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                        fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                        fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div class="scroll-y mh-420px my-5 px-8">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppFooter from './partials/AppFooter.vue'
import AppHeader from './partials/AppHeader.vue'
import AppMenu from './partials/AppMenu.vue'
import { mapGetters, mapActions } from "vuex";
import Ls from "@/services/ls";
import {useLocales} from "@/composables/locales";

export default {
  data() {
    const { setLocale, locale } = useLocales()
    return {
      notifications: [],
      formCreateData: {
        object: '',
        value: '',
        description: '',
      },
      getSupportObjets : [],
      getObjetValues : [],
      filteredObjectValuesData: [],
      setLocale,
      locale
    }
  },

  components: {
    AppFooter, AppHeader, AppMenu
  },

  computed: {
    ...mapGetters('permission', [
      'permissions'
    ]),
  },


  updated() {
    this.$nextTick(() => {
      this.initComponents()
    })
  },

  async mounted() {
    this.handleLocale()
    await this.loadPermissions()
    await this.loadModules()
    await this.getCurrentUser()
    await this.loadOfficesBySocieties()
    this.initComponents()
  },

  methods: {
    ...mapActions('offices', [
      'loadOfficesBySocieties'
    ]),

    ...mapActions('permission', [
      'loadPermissions'
    ]),
    ...mapActions('moduleAccess', [
      'loadModules'
    ]),
    ...mapActions('user', [
      'getCurrentUser'
    ]),

    ...mapActions(['toggleAsideState']),

    initComponents(){
      KTMenu.init();
      KTDrawer.init();
      KTToggle.init()
      KTUtil.init()
      KTApp.init()
    },

    handleLocale() {
      const locale = Ls.get('locale')
      if(locale) {
        this.setLocale(locale)
      }
    },

    toggleAside() {
      this.toggleAsideState()
    }

  }

}
</script>

<style>
#kt_wrapper.wrapper {
  background: linear-gradient(rgba(0, 114, 206, 0.1), rgba(240, 120, 23, 0.1));
}
</style>

