<template>
    <Label v-if="label" :label="label" :name="name" :required="required"/>
    <div class="d-flex" :class="getOptionsContainerClass(options)">
      <Radio
          v-for="(option, key) in options"
          :key="name + '-' + key"
          :id="name+'-'+key"
          :name="name"
          :label="option"
          :value="option"
          v-model="model"
          :required="required"
          class="me-5 my-3"
      />
    </div>
    <div :class="[
            {'d-block': errors && errors.length},
            {'d-none': !(errors && errors.length)},
        ]" class="invalid-feedback">
      {{ errors[0] }}
    </div>
  </template>
  
  <script setup>
  
import Label from "../../Label.vue";
import Radio from "./Radio.vue";
  
  const model = defineModel()
  
  const props = defineProps({ 
    label: String,
    name: String,
    help: String,
    containerClass: String,
    options: { type: Array, default: () => []},
    required: Boolean,
    errors: { type: Array, default: () => []}
  })

  console.log("rad", props.options)
  
  const getOptionsContainerClass = (options) => {
    if (Object.keys(options).length > 2) {
      return 'flex-column';
    }
    return ''
  }
  
  </script>
  
  <style scoped>
  
  </style>