<template>
  <Label v-if="label" :label="label" :name="name" :required="required"/>
  <div class="d-flex" :class="getOptionsContainerClass(options)">
    <Checkbox
        v-for="(option, key) in options"
        :key="name + '-' + key"
        :id="name+'-'+key"
        :name="name"
        :label="option"
        :value="option"
        :checked="model.includes(option)"
        @change="toggleOption(option)"
        class="me-5 my-3"
    />
  </div>
  <div :class="[
          {'d-block': errors && errors.length},
          {'d-none': !(errors && errors.length)},
      ]" class="invalid-feedback">
    {{ errors[0] }}
  </div>
</template>

<script setup>

import Label from "../../Label.vue";
import Checkbox from "./Checkbox.vue";

const model = defineModel({ type: Array, default: () => [] });

const props = defineProps({ 
  label: String,
  name: String,
  help: String,
  containerClass: String,
  options: { type: Array, default: () => []},
  required: Boolean,
  errors: { type: Array, default: () => []}
})

console.log("check", props.options)

const getOptionsContainerClass = (options) => {
  if (Object.keys(options).length > 2) {
    return 'flex-column';
  }
  return ''
}

const toggleOption = (option) => {
  const data = JSON.parse(JSON.stringify(model.value))
  const index = data.indexOf(option);
  if (index === -1) {
    data.push(option);
  } else {
    data.splice(index, 1);
  }

  model.value = data
};


</script>

<style scoped>

</style>