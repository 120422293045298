import i18n from "@/plugins/i18n"


export default {
    menus:  {
        sectionTitle: i18n.global.t("menu.chat.title"),
        module : [],
        permission: "",
        children: [
            {
                title: i18n.global.t('menu.chat.title'),
                icon: 'fa fa-message icon-right2',
                route: 'chats',
                group: ['chats'],
                id: 'step-chat-view',
                permission: [],
                children: [],
                module: [],
                query: {viewType: 'list'}
            }
        ]
    }
}
