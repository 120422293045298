import * as types from './mutation-types'
import store from '@/store/index'

export default {

  [types.ADD_CONVERSATIONS](state, conversations) {
    conversations.forEach((c) => {
      let conversation = state.conversations[c.id] || {messages: [], count: 0}
      conversation = {...conversation, ...c}
      state.conversations = {...state.conversations, ...{[c.id]: conversation}}

      window.Echo.channel(`App.Conversation.${c.id}`)
        .listen('.Core\\v1\\Chat\\Events\\NewMessageEvent', (e) => {
          if(store.getters['user/currentUser']?.id !== e.message.from_id) {
            let conversation = state.conversations[c.id] || {count: 0, messages: []}
            conversation.count++
            conversation.messages.push(e.message)
            state.conversations = {...state.conversations, ...{[c.id]: conversation}}
          }
        })
    })
  },

  [types.ADD_CONVERSATION](state, conversation) {
    state.conversations = {...state.conversations, ...{[conversation.id]: conversation}}
  },

  [types.ADD_MESSAGES](state, { messages, id, count }) {
    let conversation = state.conversations[id] || {}
    conversation.messages = messages
    conversation.count = count
    conversation.loaded = true
    state.conversations = {...state.conversations, ...{[id]: conversation}}
  },

  [types.ADD_MESSAGE](state, { message, id }) {
    let conversation = state.conversations[id] || {count: 0, messages: []}
    conversation.count++
    conversation.messages.push(message)
    state.conversations = {...state.conversations, ...{[id]: conversation}}
  },

  [types.ADD_USERS](state, users) {
    state.users = users
  },



}
