import * as types from './mutation-types'

export default {
  [types.ADD_OTHERS](state, data) {
    state.others = data.others
  },
  [types.ADD_OTHER] (state, data) {
    state.others.push(data)
  },
}
