import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";

const expenseGroup = ['expenses-summary-stats', 'office-users-expenses-overview','office-expenses','office-users-expenses-list', 'office-user-expense-details',]
const accountGroup = ['finances-accounts', 'finances-accounts-view']


export default {
    menus:  {
        sectionTitle: i18n.global.t('menu.finance.title'),
        module : [moduleAbilities.FUND,moduleAbilities.EXPENSE],
        permission: abilities.VIEW_FINANCE,
        children: [
            {
                title: i18n.global.t('menu.financial.accounts'),
                icon: 'fas fa-credit-card icon-right2',
                route: 'finances-accounts',
                group: accountGroup,
                id: 'step-finances-accounts',
                permission: [],
                children: [],
                module: [moduleAbilities.FUND],
            },
            {
                title: i18n.global.t('menu.finance.expenses'),
                icon: 'fas fa-handshake icon-right2',
                route: 'expenses-summary-stats',
                group: expenseGroup,
                id: 'step-expense-summary',
                permission: [],
                children: [],
                module: [moduleAbilities.EXPENSE],
                query: {viewType: 'list'}
            },
        ]
    }

}
