import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | RH Routes
    |--------------------------------------------------------------------------|
    */

    path: 'rh',
    name: 'rh',
    children: [
        /*
        |--------------------------------------------------------------------------
        | Payslips Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'payslips',
            children: [
                {
                    path: '',
                    name: 'payslips',
                    component: () => import('@/Modules/RH/views/Payslip/Index'),
                    meta: {  requiresPermission: []},
                },
                {
                    path: ':office/create',
                    name: 'payslips-office-create',
                    component: () => import('@/Modules/RH/views/Payslip/Create'),
                    meta: {  requiresPermission: []},
                },
                {
                    name: "payslip-file",
                    path: ":id/file" ,
                    component: () => import('@/Modules/RH/views/Payslip/File.vue'),
                    meta: { requiresPermission:  "" }
                },
            ]
        },
        {
            path: 'settings/payslips',
            name: 'rh-settings-payslips',
            component: () => import('@/Modules/RH/views/Setting/Layouts/BasePayslipSetting.vue'),
            children: [
                {
                    name: "rh-settings-payslips-pensions",
                    path: "pensions",
                    component: () => import('@/Modules/RH/views/Setting/pension/Index.vue'),
                    meta: { requiresPermission:  "" }
                },
                {
                    name: "rh-settings-payslips-allowances",
                    path: "allowances",
                    component: () => import('@/Modules/RH/views/Setting/allowance/Index.vue'),
                    meta: { requiresPermission:  "" }
                },
                {
                    name: "rh-settings-payslips-others",
                    path: "others",
                    component: () => import('@/Modules/RH/views/Setting/others/Index.vue'),
                    meta: { requiresPermission:  "" }
                },
                {
                    name: "rh-settings-payslips-taxes",
                    path: "taxes",
                    component: () => import('@/Modules/RH/views/Setting/taxes/Index.vue'),
                    meta: { requiresPermission:  "" }
                },
            ]
        },

        {
            name: "contracts",
            path: "contracts",
            component: () => import('@/Modules/RH/views/Contracts/Index.vue'),
            meta: { requiresPermission:  "" }
        },

        {
            name: "user-absences",
            path: "user_absences",
            component: () => import('@/views/User/LayoutUserAccount/BaseSettingUserLayout.vue'),
            children: [
                {
                    path: '',
                    component: () => import('@/Modules/RH/views/UserAbsences/Index.vue'),
                    name: 'absences',
                    meta: { requiresPermission: abilities.VIEW_USER_PROFILE },
                },
            ]
        },
        /*
        |--------------------------------------------------------------------------
        | Absences Admin Routes
        |--------------------------------------------------------------------------|
        */
        {
            path: 'absences',
            component: () => import('@/Modules/RH/views/Absences/Index.vue'),
            name: 'rh-absences',
            meta: { requiresPermission: abilities.VIEW_USER_PROFILE },
        },
        {
            path: 'absence/:society/:id/view',
            component:  () => import('@/Modules/RH/views/Absences/View.vue'),
            name: 'rh-absences-view',
            meta: { requiresPermission: abilities.VIEW_USER_PROFILE },
        },
    ]

}
