import * as types from './mutation-types'


export const loadModules = ({ commit, dispatch, state }) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/suit/installed/modules').then((response) => {
            console.log("response", response)
            commit(types.ADD_MODULES, response.data?.modules)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


