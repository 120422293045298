import checklists from "./modules/checklists"
import comments from "./modules/comments"
import files from "./modules/files"
import milestoneCategories from "./modules/milestoneCategories"
import projects from "./modules/projects"
import taskPriorities from "./modules/taskPriorities"
import tasks from "./modules/tasks"
import taskStatus from "./modules/taskStatus"

export default {
    modules: {
        projects,
        milestoneCategories,
        taskPriorities,
        taskStatus,
        tasks,
        checklists,
        files,
        comments
    }
}
