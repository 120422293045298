import * as types from './mutation-types'


export const loadTasks = ({commit}) => {
    return new Promise((resolve, reject) => {
        window.axios.get('tasks/collections').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
 
export const getProjectTasks = ({commit}, data) => {
    let query = data.filter
    return new Promise((resolve, reject) => {
        window.axios.get('tasks/'+data.id+'/details', {
            params: {
                search: query?.search,
                start_date: query?.startDate,
                end_date: query?.endDate,
                milestone_category_id: query?.milestone,
                task_priority_id: query?.priority,
                status: query?.status,
            }
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const getTask = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get('tasks/'+id+'/details/view').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const createTask = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('tasks/store', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateTask = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.put('tasks/' + data.id + '/update', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const changeTaskStatut = ({commit}, data) => {

    return new Promise((resolve, reject) => {
        window.axios.post('tasks/change/status', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteTask = ({commit}, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete('delete/'+id+'/Task').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}