<template>
  <button :disabled="isDisabled || isLoading"
          :style="buttonStyle"
          :class="[ btnClass || '',defaultClass]"
          :type="type == 'cancel' ? 'button' : type"
          :data-kt-indicator="isLoading ? 'on' : ''"
          :title="title"
          data-bs-toggle="tooltip" 
          data-bs-placement="top"
  >
    <span v-if="btnText" class="indicator-progress">
      <span v-if="spinnerDir === 'left'" class="spinner-border spinner-border-sm align-middle me-2"></span>
      {{ $t("loadingText") }}
      <span v-if="spinnerDir === 'right'" class="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span>

    <i v-if="!isLoading" :class="[iconClass,iconSize]" class="text-white"></i>
    <span v-if="btnText" class="indicator-label">{{  $t(btnText)  }}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: ""
    },
    btnClass: String,
    iconClass: String,
    color: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      required: false
    },
    btnText: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'auto'
    },
    iconSize: {
      type: String,
      default: 'fs-3'
    },
    spinnerDir: {
      type: String,
      default: "right"
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    title: String,
    height: {
      type: String,
      default: "none"
    },
  },

  computed: {
    buttonStyle() {
      const baseStyle = {
        borderRadius: '8px',
        borderWidth: '0',
        borderStyle: 'none',
        padding: this.size
      };

      switch (this.type) {
        case 'submit':
          return { ...baseStyle, color: '#fff', backgroundColor:  this.backgroundColor || 'rgba(0, 114, 206, 1)' };
        case 'new':
          return { ...baseStyle, color: '#fff', backgroundColor: this.backgroundColor || 'rgba(0, 114, 206, 1)' };
        case 'cancel':
          return { ...baseStyle, color: '#646E7B', backgroundColor: this.backgroundColor || '#E6E7E9' };
        default:
          return { ...baseStyle, color: '#fff', backgroundColor: this.color };
      }
    },
    defaultClass(){
      if(!this.btnText && this.iconClass){
          return "btn btn-icon btn_class shadow"
      }else{
        return "btn btn_class shadow"
      }
    }
  }
}
</script>

<style scoped>
.btn_class {
  border-radius: 8px;
}
</style>
