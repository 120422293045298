import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";


export default {
    menus:  {
        sectionTitle: i18n.global.t("menu.fm.title"),
        module : [],
        permission: "",
        children: [
            {
                title: i18n.global.t('menu.fm.documents'),
                icon: 'fas fa-folder icon-right2',
                route: 'file-manager-view',
                group: [],
                id: 'step-file-manager-view',
                permission: [abilities.VIEW_OPPORTUNITIES, abilities.VIEW_ALL_OPPORTUNITIES],
                children: [],
                module: [moduleAbilities.PIPELINE],
                query: {viewType: 'list'}
            }
        ]
    }
}
