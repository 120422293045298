import * as types from './mutation-types'

export default {
  [types.ADD_PROJECT_MILESTONES](state, data) {
    state.allMilestones = data.data
  },
  [types.ADD_TASK_MILESTONES](state, data) {
    state.milestones = data.data
  },
  [types.ADD_TASK_MILESTONE] (state, data) {
    state.milestones.push(data)
  }
}
