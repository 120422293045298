import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";

const npsGroup = ['nps',"nps-list", "nps-create", "nps-views", "nps-preview"]

export default {
    menus:  {
        sectionTitle: i18n.global.t('menu.nps.title'),
        module : [],
        permission: [],
        children: [
            {
                title: i18n.global.t('menu.nps.title'),
                icon: 'fas fa-star icon-right2',
                route: 'nps-list',
                group: npsGroup,
                id: 'step-nps',
                permission: [],
                children: [],
                module: [],
            }
        ]
    }
}
