import moduleAbilities from "@/composables/moduleAbilities";
import abilities from "@/composables/abilities";

export default {
    /*
    |--------------------------------------------------------------------------
    | projects Routes
    |--------------------------------------------------------------------------|
    */

    path: 'projects',
    name: 'projects',
    children: [
        {
            path: 'list',
            component: () => import('@/Modules/Projects/views/Projects/Index.vue'),
            name: 'projects-index',
            meta: {},
        },
        {
            path: ':id/view',
            component: () => import('@/Modules/Projects/views/Projects/View.vue'),
            name: 'projects-view',
            meta: {},
        },
        {
            path: ':project/tasks/:id/details',
            component: () => import('@/Modules/Projects/views/Tasks/View.vue'),
            name: 'tasks-view',
            meta: {},
        },
        
    ]

}
