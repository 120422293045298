import * as types from './mutation-types'

export const loadOthers = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.get('get/other/collection', data).then((response) => {
            commit(types.ADD_OTHERS, response.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateOther = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.patch('update/' + data.id + '/other', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const createOther = ({ commit }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('store/other', data).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}


export const deleteOther = ({ commit }, id) => {

    return new Promise((resolve, reject) => {
        window.axios.delete('delete/' + id + '/other').then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

