import Echo from 'laravel-echo';
import Ls from './services/ls'

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.VUE_APP_SOCKET_URL + ':' + process.env.VUE_APP_SOCKET_PORT,
    auth: {
        headers: {
            Authorization: "Bearer " + Ls.get('auth.token'),
            Accept: "application/json",
        },
    },
});
