import i18n from "@/plugins/i18n"
import abilities from "@/composables/abilities";
import moduleAbilities from "@/composables/moduleAbilities";

const contractGroup = ['contracts']
const payslipGroup = ["payslips", "payslips-office-create", "payslip-file"]
const absenceGroup = ['rh-absences', 'rh-absences-view']

export default {
    menus:  {
        sectionTitle: i18n.global.t("menu.rh.title"),
        module : [],
        permission: "",
        children: [
            {
                title: i18n.global.t('menu.rh.absences'),
                icon: 'fas fa-umbrella-beach icon-right2',
                route: 'rh-absences',
                group: absenceGroup,
                id: 'step-rh-absences',
                permission: [],
                children: [],
                module: [],
            },
            {
                title: i18n.global.t('menu.rh.payslips'),
                icon: 'fas fa-money-check icon-right2',
                route: 'payslips',
                group: payslipGroup,
                id: 'step-payslip-summary',
                permission: [],
                children: [],
                module: [],
                query: {}
            },
            {
                title: i18n.global.t('menu.rh.contracts'),
                icon: 'fas fa-file icon-right2',
                route: 'contracts',
                group: contractGroup,
                id: 'step-rh-contracts',
                permission: [],
                children: [],
                module: [],

            },

        ]
    }
}
